/* eslint-disable operator-linebreak */
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Description.styles';
import boardImg from '../../assets/icons/board.svg';
import SectionWrapper from '../Common/SectionWrapper';
import { theme } from '../../styles/theme';
import PrimaryButton from '../Common/Buttons/PrimaryButton';
import scrollToById from '../../utils/scrollToById';
import { sectionIds } from '../../static/constants/sectionIds';
import SectionHeaderText from '../Common/Texts/SectionHeaderText';

const Description = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper
      sectionId={sectionIds.homeDescription}
      backgroundColor={theme.color.backgroundSecondary}
    >
      <SectionHeaderText>{t('home_what_we_do_subheader')}</SectionHeaderText>
      <S.Wrapper>
        <S.Description>{t('home_what_we_do_1')}</S.Description>
        <S.Description>
          {t('home_what_we_do_2_pt1')}
          <S.DescriptionSpanOrange>
            {' '}
            {t('home_what_we_do_2_span_orange')}
          </S.DescriptionSpanOrange>{' '}
          {t('home_what_we_do_2_pt2')}
          <S.DescriptionSpanBlack>
            {' '}
            {t('home_what_we_do_2_span_black_1')}
          </S.DescriptionSpanBlack>{' '}
          {t('home_what_we_do_2_pt3')}
          <S.DescriptionSpanBlack>
            {' '}
            {t('home_what_we_do_2_span_black_2')}
          </S.DescriptionSpanBlack>
        </S.Description>
        <S.ImageWrapper>
          <S.Image src={boardImg} alt="board" />
        </S.ImageWrapper>
        <S.Description>{t('home_what_we_do_3')}</S.Description>
        <S.ButtonWrapper>
          <PrimaryButton
            onClick={() => {
              scrollToById(sectionIds.homeWorkshopDetails);
            }}
          >
            {t('home_what_we_do_button')}
          </PrimaryButton>
        </S.ButtonWrapper>
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default Description;
