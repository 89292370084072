import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

const sectionGap = '2rem';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media ${query.desktop} {
    margin-top: 4.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 0;

  @media ${query.desktop} {
    grid-column: 2/3;
    margin-left: ${sectionGap};
  }
`;

export const DescriptionSpanOrange = styled.span`
  color: ${({ theme }) => theme.color.primaryOrange};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

export const DescriptionSpanBlack = styled.span`
  color: ${({ theme }) => theme.color.textSecondary};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  position: relative;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${query.desktop} {
    grid-column: 1/2;
    grid-row: 1/6;
    margin-right: ${sectionGap};
  }
`;

export const Image = styled.img`
  max-width: 45rem;
  align-self: center;
  width: 100%;

  @media ${query.desktop} {
    max-width: none;
    min-width: 40rem;
  }
`;

export const ButtonWrapper = styled.div`
  align-self: center;
  width: 100%;

  @media ${query.desktop} {
    align-self: flex-start;
    width: auto;
    grid-column: 2/3;
    margin-left: ${sectionGap};
    margin-top: 3rem;
  }
`;
