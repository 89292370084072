import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionWrapper from '../Common/SectionWrapper';
import PrimaryButtonWithLink from '../Common/Buttons/PrimaryButtonWithLink';
import routes from '../../static/constants/routes';
import { sectionIds } from '../../static/constants/sectionIds';
import buldingProcessIcon from '../../assets/icons/building-process.svg';
import debateIcon from '../../assets/icons/debate.svg';

import * as S from './WorkshopDetails.styles';

const WorkshopDetails = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper sectionId={sectionIds.homeWorkshopDetails}>
      <S.Header>{t('workshopDetailsSection1Header')}</S.Header>
      <S.InnerWrapper>
        <S.DescriptionWrapper>
          <S.Paragraph>{t('workshopDetailsSection1Description')}</S.Paragraph>

          <PrimaryButtonWithLink
            text={t('workshopDetailsSection1Button')}
            routeTo={routes.blog}
          />
        </S.DescriptionWrapper>

        <S.ImageWrapper>
          <S.Image src={buldingProcessIcon} alt="people laughing" />
        </S.ImageWrapper>
      </S.InnerWrapper>

      <S.Header>{t('workshopDetailsSection2Header')}</S.Header>
      <S.InnerWrapper $isReversed>
        <S.DescriptionWrapper>
          <S.Paragraph>{t('workshopDetailsSection2Description')}</S.Paragraph>
          <PrimaryButtonWithLink
            text={t('workshopDetailsSection2Button')}
            routeTo={routes.offer}
          />
        </S.DescriptionWrapper>

        <S.ImageWrapper>
          <S.Image src={debateIcon} alt="people debating" />
        </S.ImageWrapper>
      </S.InnerWrapper>
    </SectionWrapper>
  );
};

export default WorkshopDetails;
