import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';
import SectionHeaderText from '../Common/Texts/SectionHeaderText';

export const InnerWrapper = styled.div<{ $isReversed?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6.4rem;

  @media ${query.desktop} {
    margin-top: 5.4rem;
    flex-direction: ${({ $isReversed }) =>
      $isReversed ? 'row-reverse' : 'row'};
  }

  &:not(:last-of-type) {
    margin-bottom: 3.6rem;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
`;

export const Header = styled(SectionHeaderText)``;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 0 auto;
  line-height: 2.8rem;

  @media ${query.desktop} {
    width: auto;
    margin: 0;
    margin-top: 2.4rem;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${query.desktop} {
    width: 90%;
  }
`;

export const Image = styled.img`
  width: 100%;
  margin: auto;

  @media ${query.desktop} {
    min-width: 600px;
  }
`;
