import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Hero.styles';
import routes from '../../static/constants/routes';
import PrimaryButtonWithLink from '../Common/Buttons/PrimaryButtonWithLink';
import tableImg from '../../assets/icons/table.svg';
import arrowImg from '../../assets/icons/arrow-orange.svg';
import waveImg from '../../assets/icons/wave-big-orange.svg';
import dotsImg from '../../assets/icons/dots-orange.svg';
import SectionWrapper from '../Common/SectionWrapper';
import { theme } from '../../styles/theme';
import scrollToById from '../../utils/scrollToById';
import { sectionIds } from '../../static/constants/sectionIds';
import useMediaQuery from '../../hooks/useMediaQuery';
import { query } from '../../styles/mediaQuery';

const Hero = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(query.desktop);

  return (
    <SectionWrapper backgroundColor={theme.color.backgroundPrimary}>
      <S.HeroWrapper>
        <S.DotsImg src={dotsImg} alt="" />
        <S.ImageWrapper>
          <S.Image
            src={tableImg}
            width={isDesktop ? '100%' : 450}
            height={isDesktop ? '100%' : 336}
            alt={t('meta_alt_people_table_icon')}
          />
        </S.ImageWrapper>
        <S.Content>
          <S.WaveImg src={waveImg} alt="" />
          <S.HeadingContainer>
            <S.Header>
              {t('home_header1')}
              <S.HeaderSpan>{t('home_header2')}</S.HeaderSpan>
            </S.Header>
          </S.HeadingContainer>
          <div>
            <S.Description>{t('home_description1')}</S.Description>
            <S.Description>{t('home_description2')}</S.Description>
          </div>
          <S.ButtonWrapper>
            <PrimaryButtonWithLink
              text={t('btn_offer')}
              routeTo={routes.offer}
            />
          </S.ButtonWrapper>
        </S.Content>
        <S.ArrowButton
          type="button"
          onClick={() => {
            scrollToById(sectionIds.homeDescription);
          }}
        >
          <img src={arrowImg} alt="arrow-icon" />
        </S.ArrowButton>
      </S.HeroWrapper>
    </SectionWrapper>
  );
};

export default Hero;
