import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${query.desktop} {
    display: grid;
    grid-template-columns: 45% 55%;
    justify-content: center;
    gap: 4rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  @media ${query.desktop} {
    gap: 3.7rem;
    grid-row: 1/2;
  }
`;

export const HeadingContainer = styled.div`
  margin-top: 3rem;

  @media ${query.desktop} {
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${query.desktop} {
    grid-column: 2/3;
  }
`;

export const Image = styled.img`
  max-width: 45rem;
  align-self: center;
  width: 100%;

  @media ${query.desktop} {
    max-width: none;
    min-width: 40rem;
  }
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.heading};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 1.6rem 0;

  @media ${query.desktop} {
    font-size: 6.4rem;
    margin: 0;

    &:first-child {
      margin-bottom: 1.6rem;
    }
  }
`;

export const HeaderSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.color.primaryOrange};
`;

export const Description = styled.h4`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.button};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 0;

  @media ${query.desktop} {
    font-size: ${({ theme }) => theme.font.size.paragraphBig};
  }

  &:first-child {
    margin-bottom: 1.2rem;
  }
`;

export const ButtonWrapper = styled.div`
  align-self: center;
  width: 100%;
  margin-top: 2.4rem;

  @media ${query.desktop} {
    align-self: flex-start;
    width: auto;
    margin: 0;
  }
`;

export const ArrowButton = styled.button`
  margin-top: 1.4rem;
  align-self: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 1rem 3rem;

  @media ${query.desktop} {
    display: none;
  }
`;

export const WaveImg = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    top: 1rem;
    right: -4rem;
  }
`;
export const DotsImg = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    bottom: -6rem;
    right: -35rem;
    background-size: cover;
  }
`;
